(function ($) {
  $('.tada-animate').hover(function () {
    $(this).toggleClass('animated tada')
  });
})(jQuery);

function escapeRegExp(str) {
  return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
}

function replaceAll(str, find, replace) {
  return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

(function ($) {
$(document).ready(function () {

  $('form.form-validated').each(function () {
    $(this).validate({
      errorPlacement: function (error, element) {
        if (element.attr('type') == "checkbox" || element.attr('type') == "radio") {
          var $element = $(element);
          if ($element.parent().hasClass('checkboxes-and-radios') || $element.parent().parent().hasClass('checkboxes-and-radios')) {
            $element.closest('.checkboxes-and-radios').append(error);
          } else {
            error.insertAfter(element);
          }
        } else {
          error.insertAfter(element);
        }
      },
    });
  });

  function pad(str, max) {
    str = str.toString();
    return str.length < max ? pad("0" + str, max) : str;
  };

  $.fn.moneyMask = function () {
    this.on('click focus', function () {
      var self = this;
      setTimeout(function () {
        self.value = self.value;
      }, 15); //move cursor to end
    })
    .on('keypress', function (e) {
      var $this = $(this);
      var currentValue = $this.val().toString();

      if (currentValue.length <= 3) {
        $this.val(pad(currentValue, 3));
      } else {
        $this.val(currentValue.replace(/^0+/, ''));
      }
    });
  };
});

})(jQuery);

(function ($) {

/*
* Translated default messages for the jQuery validation plugin.
* Locale: PT (Portuguese; português)
* Region: BR (Brazil)
*/
$.extend($.validator.messages, {

  // Core
  required: "Campo &eacute; obrigat&oacute;rio.",
  remote: "Por favor, corrija este campo.",
  email: "E-mail inv&aacute;lido.",
  url: "Por favor, forne&ccedil;a uma URL v&aacute;lida.",
  date: "Por favor, forne&ccedil;a uma data v&aacute;lida.",
  dateISO: "Por favor, forne&ccedil;a uma data v&aacute;lida (ISO).",
  number: "Por favor, forne&ccedil;a um n&uacute;mero v&aacute;lido.",
  digits: "Por favor, forne&ccedil;a somente d&iacute;gitos.",
  creditcard: "Por favor, forne&ccedil;a um cart&atilde;o de cr&eacute;dito v&aacute;lido.",
  equalTo: "Por favor, forne&ccedil;a o mesmo valor novamente.",
  maxlength: $.validator.format("Por favor, forne&ccedil;a n&atilde;o mais que {0} caracteres."),
  minlength: $.validator.format("Por favor, forne&ccedil;a ao menos {0} caracteres."),
  rangelength: $.validator.format("Por favor, forne&ccedil;a um valor entre {0} e {1} caracteres de comprimento."),
  range: $.validator.format("Por favor, forne&ccedil;a um valor entre {0} e {1}."),
  max: $.validator.format("Por favor, forne&ccedil;a um valor menor ou igual a {0}."),
  min: $.validator.format("Por favor, forne&ccedil;a um valor maior ou igual a {0}."),

  // Metodos Adicionais
  maxWords: $.validator.format("Por favor, forne&ccedil;a com {0} palavras ou menos."),
  minWords: $.validator.format("Por favor, forne&ccedil;a pelo menos {0} palavras."),
  rangeWords: $.validator.format("Por favor, forne&ccedil;a entre {0} e {1} palavras."),
  accept: "Por favor, forne&ccedil;a um tipo v&aacute;lido.",
  alphanumeric: "Por favor, forne&ccedil;a somente com letras, n&uacute;meros e sublinhados.",
  bankaccountNL: "Por favor, forne&ccedil;a com um n&uacute;mero de conta banc&aacute;ria v&aacute;lida.",
  bankorgiroaccountNL: "Por favor, forne&ccedil;a um banco v&aacute;lido ou n&uacute;mero de conta.",
  bic: "Por favor, forne&ccedil;a um c&oacute;digo BIC v&aacute;lido.",
  cifES: "Por favor, forne&ccedil;a um c&oacute;digo CIF v&aacute;lido.",
  creditcardtypes: "Por favor, forne&ccedil;a um n&uacute;mero de cart&atilde;o de cr&eacute;dito v&aacute;lido.",
  currency: "Por favor, forne&ccedil;a uma moeda v&aacute;lida.",
  dateFA: "Por favor, forne&ccedil;a uma data correta.",
  dateITA: "Por favor, forne&ccedil;a uma data correta.",
  dateNL: "Por favor, forne&ccedil;a uma data correta.",
  extension: "Por favor, forne&ccedil;a um valor com uma extens&atilde;o v&aacute;lida.",
  giroaccountNL: "Por favor, forne&ccedil;a um n&uacute;mero de conta corrente v&aacute;lido.",
  iban: "Por favor, forne&ccedil;a um c&oacute;digo IBAN v&aacute;lido.",
  integer: "Por favor, forne&ccedil;a um n&uacute;mero n&atilde;o decimal.",
  ipv4: "Por favor, forne&ccedil;a um IPv4 v&aacute;lido.",
  ipv6: "Por favor, forne&ccedil;a um IPv6 v&aacute;lido.",
  lettersonly: "Por favor, forne&ccedil;a apenas com letras.",
  letterswithbasicpunc: "Por favor, forne&ccedil;a apenas letras ou pontua&ccedil;ões.",
  mobileNL: "Por favor, fornece&ccedil;a um n&uacute;mero v&aacute;lido de telefone.",
  mobileUK: "Por favor, fornece&ccedil;a um n&uacute;mero v&aacute;lido de telefone.",
  nieES: "Por favor, forne&ccedil;a um NIE v&aacute;lido.",
  nifES: "Por favor, forne&ccedil;a um NIF v&aacute;lido.",
  nowhitespace: "Por favor, n&atilde;o utilize espa&ccedil;os em branco.",
  pattern: "O formato fornenecido &eacute; inv&aacute;lido.",
  phoneNL: "Por favor, fornece&ccedil;a um n&uacute;mero de telefone v&aacute;lido.",
  phoneUK: "Por favor, fornece&ccedil;a um n&uacute;mero de telefone v&aacute;lido.",
  phoneUS: "Por favor, fornece&ccedil;a um n&uacute;mero de telefone v&aacute;lido.",
  phonesUK: "Por favor, fornece&ccedil;a um n&uacute;mero de telefone v&aacute;lido.",
  postalCodeCA: "Por favor, fornece&ccedil;a um n&uacute;mero de c&oacute;digo postal v&aacute;lido.",
  postalcodeIT: "Por favor, fornece&ccedil;a um n&uacute;mero de c&oacute;digo postal v&aacute;lido.",
  postalcodeNL: "Por favor, fornece&ccedil;a um n&uacute;mero de c&oacute;digo postal v&aacute;lido.",
  postcodeUK: "Por favor, fornece&ccedil;a um n&uacute;mero de c&oacute;digo postal v&aacute;lido.",
  postalcodeBR: "Por favor, forne&ccedil;a um CEP v&aacute;lido.",
  require_from_group: $.validator.format("Por favor, forne&ccedil;a pelo menos {0} destes campos."),
  skip_or_fill_minimum: $.validator.format("Por favor, optar entre ignorar esses campos ou preencher pelo menos {0} deles."),
  stateUS: "Por favor, forne&ccedil;a um estado v&aacute;lido.",
  strippedminlength: $.validator.format("Por favor, forne&ccedil;a pelo menos {0} caracteres."),
  time: "Por favor, forne&ccedil;a um hor&aacute;rio v&aacute;lido, no intervado de 00:00 e 23:59.",
  time12h: "Por favor, forne&ccedil;a um hor&aacute;rio v&aacute;lido, no intervado de 01:00 e 12:59 am/pm.",
  url2: "Por favor, fornece&ccedil;a uma URL v&aacute;lida.",
  vinUS: "O n&uacute;mero de identifica&ccedil;&atilde;o de ve&iacute;culo informada (VIN) &eacute; inv&aacute;lido.",
  zipcodeUS: "Por favor, fornece&ccedil;a um c&oacute;digo postal americano v&aacute;lido.",
  ziprange: "O c&oacute;digo postal deve estar entre 902xx-xxxx e 905xx-xxxx",
  cpfBR: "Por favor, forne&ccedil;a um CPF v&aacute;lido."
});

})(jQuery);

(function ($) {

// Mobile menus
$(function() {
  $(document).on('click', '[data-menu]', function(event) {
    event.preventDefault();

    $this = $(this);
    $menu = $($this.attr('data-menu'));

    if ($menu && !$menu.hasClass('open')) {
      // $('body').addClass('open-menu');
      $this.addClass('open');
      $menu.addClass('open');
    } else if ($menu) {
      // $('body').removeClass('open-menu');
      $this.removeClass('open');
      $menu.removeClass('open');
    }
  });

  $(document).on('click', '.masthead-mobile-menu-btn', function(event) {
    event.preventDefault();
    $('body').toggleClass('open-mobile-menu');
  });

  $(document).on('click', '.masthead-mobile-categories-menu-btn', function(event) {
    if (window.innerWidth <= 1380) {
      event.preventDefault();
    }
    $('body').toggleClass('open-mobile-categories-menu');
  });
});

// Woocomemrce ordering
$(function() {
  $('form.woocommerce-ordering select').on('change', function() {
    $this = $(this);

    $this.closest('form').submit();
  });
})

// Posts categories
$(function() {
  // $(document).on('click', '.posts .posts-categories .posts-categories-listing a', function() {

  //   $this = $(this);

  //   $('.posts .posts-categories .posts-categories-listing li').removeClass('active');
  //   $this.closest('li').addClass('active');
  // });

  $('.posts .posts-categories .posts-categories-listing li.active').each(function() {
    $this = $(this);

    $this.parents('li').addClass('active');
  });
});

// Select2
$(function() {
  $('[data-type="select2"]').select2();
});


// Representative states
$(function() {
  $('#representative-states').on('change', function() {
    console.log($(this).find(":checked").val());
    $('.representatives .representatives-state.active').removeClass('active');

    $('#representatives-state-' + $(this).find(":checked").val()).addClass('active');
  });
});

// Clipboard.js
$(function () {
  var clipboard = new ClipboardJS('.btn-clipboard');
});

// JSsocials
$(function() {
  $(".product-header #share").jsSocials({
    shares: ["email", "twitter", "facebook", "googleplus", "pinterest", "whatsapp"]
  });
});

})(jQuery);

// function initMap() {
//   var mapStyle = [{
//     "featureType": "administrative",
//     "elementType": "geometry.fill",
//     "stylers": [{
//       "saturation": "0"
//     }]
//   }, {
//     "featureType": "administrative",
//     "elementType": "geometry.stroke",
//     "stylers": [{
//       "color": "#6f3b12"
//     }, {
//       "saturation": "0"
//     }]
//   }, {
//     "featureType": "administrative",
//     "elementType": "labels.text.fill",
//     "stylers": [{
//       "color": "#6f3b12"
//     }, {
//       "saturation": "0"
//     }]
//   }, {
//     "featureType": "administrative",
//     "elementType": "labels.text.stroke",
//     "stylers": [{
//       "visibility": "off"
//     }]
//   }, {
//     "featureType": "administrative.locality",
//     "elementType": "labels.icon",
//     "stylers": [{
//       "color": "#6f3b12"
//     }, {
//       "saturation": "0"
//     }, {
//       "visibility": "on"
//     }]
//   }, {
//     "featureType": "landscape",
//     "elementType": "geometry.fill",
//     "stylers": [{
//       "color": "#efebea"
//     }, {
//       "saturation": "0"
//     }]
//   }, {
//     "featureType": "landscape",
//     "elementType": "geometry.stroke",
//     "stylers": [{
//       "color": "#af9d94"
//     }, {
//       "saturation": "0"
//     }, {
//       "visibility": "off"
//     }]
//   }, {
//     "featureType": "landscape",
//     "elementType": "labels.text.fill",
//     "stylers": [{
//       "color": "#6f3b12"
//     }, {
//       "saturation": "0"
//     }]
//   }, {
//     "featureType": "landscape",
//     "elementType": "labels.text.stroke",
//     "stylers": [{
//       "visibility": "off"
//     }]
//   }, {
//     "featureType": "landscape.man_made",
//     "elementType": "geometry.fill",
//     "stylers": [{
//       "saturation": "0"
//     }]
//   }, {
//     "featureType": "poi",
//     "elementType": "geometry",
//     "stylers": [{
//       "hue": "#ff0000"
//     }, {
//       "saturation": "34"
//     }, {
//       "visibility": "off"
//     }]
//   }, {
//     "featureType": "poi",
//     "elementType": "labels.text.fill",
//     "stylers": [{
//       "color": "#6f3b12"
//     }, {
//       "saturation": "0"
//     }]
//   }, {
//     "featureType": "poi",
//     "elementType": "labels.text.stroke",
//     "stylers": [{
//       "visibility": "off"
//     }]
//   }, {
//     "featureType": "poi",
//     "elementType": "labels.icon",
//     "stylers": [{
//       "visibility": "off"
//     }]
//   }, {
//     "featureType": "road",
//     "elementType": "geometry.stroke",
//     "stylers": [{
//       "visibility": "off"
//     }]
//   }, {
//     "featureType": "road",
//     "elementType": "labels.text.fill",
//     "stylers": [{
//       "color": "#6f3b12"
//     }]
//   }, {
//     "featureType": "road",
//     "elementType": "labels.text.stroke",
//     "stylers": [{
//       "visibility": "off"
//     }]
//   }, {
//     "featureType": "road",
//     "elementType": "labels.icon",
//     "stylers": [{
//       "visibility": "off"
//     }, {
//       "lightness": "60"
//     }, {
//       "gamma": "1.00"
//     }, {
//       "hue": "#ff0000"
//     }, {
//       "saturation": "-90"
//     }]
//   }, {
//     "featureType": "road.highway",
//     "elementType": "geometry.fill",
//     "stylers": [{
//       "color": "#ffffff"
//     }, {
//       "saturation": "0"
//     }]
//   }, {
//     "featureType": "road.highway",
//     "elementType": "labels.icon",
//     "stylers": [{
//       "visibility": "simplified"
//     }, {
//       "hue": "#ff0000"
//     }, {
//       "weight": "0.01"
//     }]
//   }, {
//     "featureType": "transit",
//     "elementType": "geometry.fill",
//     "stylers": [{
//       "color": "#bfb1a9"
//     }, {
//       "saturation": "0"
//     }]
//   }, {
//     "featureType": "transit",
//     "elementType": "geometry.stroke",
//     "stylers": [{
//       "color": "#bfb1a9"
//     }, {
//       "saturation": "0"
//     }]
//   }, {
//     "featureType": "transit",
//     "elementType": "labels.text.fill",
//     "stylers": [{
//       "color": "#6f3b12"
//     }, {
//       "saturation": "0"
//     }]
//   }, {
//     "featureType": "transit",
//     "elementType": "labels.text.stroke",
//     "stylers": [{
//       "visibility": "off"
//     }]
//   }, {
//     "featureType": "transit",
//     "elementType": "labels.icon",
//     "stylers": [{
//       "visibility": "on"
//     }, {
//       "hue": "#ff0000"
//     }, {
//       "saturation": "-90"
//     }, {
//       "lightness": "0"
//     }, {
//       "gamma": "1.00"
//     }, {
//       "weight": "1"
//     }]
//   }, {
//     "featureType": "water",
//     "elementType": "all",
//     "stylers": [{
//       "color": "#dfd8d4"
//     }, {
//       "saturation": "0"
//     }]
//   }, {
//     "featureType": "water",
//     "elementType": "labels.text",
//     "stylers": [{
//       "saturation": "0"
//     }]
//   }, {
//     "featureType": "water",
//     "elementType": "labels.text.fill",
//     "stylers": [{
//       "color": "#6f3b12"
//     }, {
//       "saturation": "0"
//     }]
//   }, {
//     "featureType": "water",
//     "elementType": "labels.text.stroke",
//     "stylers": [{
//       "visibility": "off"
//     }]
//   }, {
//     "featureType": "water",
//     "elementType": "labels.icon",
//     "stylers": [{
//       "visibility": "off"
//     }]
//   }];

//   function mapIt($mapContainer, latLng) {
//     var map = new google.maps.Map($mapContainer[0], {
//       center: latLng,
//       scrollwheel: false,
//       zoom: 16,
//       styles: mapStyle,
//       mapTypeControlOptions: {
//         mapTypeIds: [google.maps.MapTypeId.ROADMAP]
//       },
//     });
//     var marker = new google.maps.Marker({
//       map: map,
//       position: latLng,
//       // icon: $mapContainer.attr('data-map-marker-path'),
//       title: $mapContainer.attr('data-map-marker-title')
//     });
//   }

//   $('[data-toggle="map"]').each(function () {
//     $this = $(this);

//     if ($this.attr('data-map-lat') && $this.attr('data-map-lng')) {
//       var latLng = {
//         lat: parseFloat($this.attr('data-map-lat')),
//         lng: parseFloat($this.attr('data-map-lng'))
//       };

//       mapIt($this, latLng);

//     } else if ($this.attr('data-map-zip-code')) {
//       var geocoder = new google.maps.Geocoder;
//       $zipCode = $this.attr('data-map-zip-code');

//       geocoder.geocode({
//         'address': $zipCode
//       }, function (results, status) {
//         if (status === 'OK') {
//           if (results[1]) {
//             var latLng = {
//               lat: results[0].geometry.location.lat(),
//               lng: results[0].geometry.location.lng()
//             };

//             mapIt($this, latLng);
//           } else {}
//         } else {}
//       });
//     }
//   });
// }

(function ($) {
  $('[data-toggle="hide-and-show"]').css('cursor', 'pointer');

  $('[data-toggle="hide-and-show"]').each(function() {
    $this = $(this);

    $target = $($this.attr('data-target'));

    if ($this.hasClass('active')) {
      $target.show();
    } else {
      $target.hide();
    }
  });

  $(document).on('click', '[data-toggle="hide-and-show"]', function(event) {
    event.preventDefault();

    $this = $(this);
    $target = $($this.attr('data-target'));
    $targetType = $($this.attr('data-target-type'));

    $('[data-target-type="' + $this.attr('data-target-type') + '"').removeClass('active');
    $this.addClass('active');
    $targetType.hide();
    $target.show();
  });

  $('.woof_container_marcas, .woof_price_search_container, .woof_container_cores').wrapAll('<div class="posts-filters"></div>');

  $('.posts-filters').prepend('<h1 class="posts-categories-call">Filtros</h1>');

})(jQuery);

(function ($) {
$(function() {
  var $postsGalleries = $('.post.post-full .gallery');

  $postsGalleries.addClass('post-gallery').addClass('slick-carousel');

  $postsGalleries.find('.gallery-item').each(function() {
    $this = $(this);
    var imageURL = $this.find('img').attr('src');

    $this.addClass('post-gallery-item');

    $this.find('div').addClass('post-gallery-image-wrapper');
    // $this.find('div').addClass('post-gallery-image-wrapper').css('background-image', 'url(' + imageURL + ')');

    $this.find('a').remove();

    $this.find('.post-gallery-image-wrapper').html('<div class="post-gallery-image" style="background-image: url(' + imageURL + ')"></div>');
  });

  $postsGalleries.slick({
    autoplay: true,
    adaptiveHeight: true,
    dots: false,
    infinite: false,
    speed: 700,
    prevArrow: '<button class="slick-arrow slick-prev"><i class="fa fa-angle-left"></i></button>',
    nextArrow: '<button class="slick-arrow slick-next"><i class="fa fa-angle-right"></i></button>',
    slidesToShow: 1,
    slidesToScroll: 1,
  });
});
})(jQuery);

(function ($) {

$(function() {
  $('#search-form').submit(function(event) {
    $this = $(this);
    $formInputText = $this.find('input[type="text"]');
    $formWrapper = $this.closest('.masthead-search-form-wrapper');

    if (!$formWrapper.hasClass('open')) {
      event.preventDefault();

      $formWrapper.addClass('open');
      $formInputText.focus();
    } else {
      
    }
  });

  $('#search-form').on('click', '.close', function(event) {
    event.preventDefault();

    $this = $(this);
    $form = $this.closest('form');
    $formInputText = $form.find('input[type="text"]');
    $formWrapper = $form.closest('.masthead-search-form-wrapper');

    $formWrapper.removeClass('open');
  });

  $(document).on('keydown', function (event) {
    if (event.keyCode === 27){
      $('.masthead-search-form-wrapper.open').find('#search-form .close').click();
    }
  });
});

})(jQuery);

(function ($) {

// Slick Carousel
$(function () {
  $('.product .product-images.slick-carousel').slick({
    adaptiveHeight: true,
    autoplay: false,
    dots: false,
    infinite: false,
    arrows: false,
    cssEase: 'linear',
    prevArrow: '<button class="slick-arrow slick-prev"><i class="fa fa-angle-left"></i></button>',
    nextArrow: '<button class="slick-arrow slick-next"><i class="fa fa-angle-right"></i></button>',
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
  });

  $('.product .product-thumbnails.slick-carousel').slick({
    dots: false,
    arrows: false,
    infinite: true,
    prevArrow: '<button class="slick-arrow slick-prev"><i class="fa fa-angle-left"></i></button>',
    nextArrow: '<button class="slick-arrow slick-next"><i class="fa fa-angle-right"></i></button>',
    slidesToShow: 4,
    slidesToScroll: 1,
    vertical: true,
    focusOnSelect: true,
    asNavFor: '.product .product-images.slick-carousel',
    infinite: false,
    responsive: [{
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
      }
    }]
  });

  $('.highlights.slick-carousel').slick({
    adaptiveHeight: false,
    autoplay: false,
    dots: false,
    infinite: true,
    speed: 700,
    cssEase: 'linear',
    prevArrow: '<button class="slick-arrow slick-prev"><i class="fa fa-angle-left"></i></button>',
    nextArrow: '<button class="slick-arrow slick-next"><i class="fa fa-angle-right"></i></button>',
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 6000,
  });

  $('.highlights-ii.slick-carousel').slick({
    adaptiveHeight: false,
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 700,
    fade: true,
    cssEase: 'linear',
    prevArrow: '<button class="slick-arrow slick-prev"><i class="fa fa-angle-left"></i></button>',
    nextArrow: '<button class="slick-arrow slick-next"><i class="fa fa-angle-right"></i></button>',
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 7000,
    responsive: [{
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        dots: true,
        arrows: false,
      }
    }]
  });

  $('.section.section-courses .courses-listing.slick-carousel').slick({
    responsive: [{
      breakpoint: 10000,
      settings: "unslick",
    }, {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        arrows: false,
        dots: false,
      }
    }]
  });

  $('.section-hot-products .products-listing.slick-carousel').slick({
    adaptiveHeight: false,
    autoplay: false,
    dots: false,
    infinite: false,
    speed: 700,
    cssEase: 'linear',
    prevArrow: '<button class="slick-arrow slick-prev"><i class="fa fa-angle-left"></i></button>',
    nextArrow: '<button class="slick-arrow slick-next"><i class="fa fa-angle-right"></i></button>',
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplaySpeed: 6000,
    responsive: [{
      breakpoint: 1280,
      settings: {
        slidesToShow: 3,
      }
    }, {
      breakpoint: 980,
      settings: {
        slidesToShow: 2,
      }
    }, {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
      }
    }, {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        centerMode: true,
      }
    }]
  });
});

})(jQuery);
